import { commonModuleGroup, podcastModuleGroup } from "modules/groups";
import { Redirect, Switch } from "react-router-dom";

import RegisteredRoute from "components/Common/RegisteredRoute";
import RegistrationRequired from "pages/Auth/RegistrationRequired";
import TopLevelRoute from "pages/Common/TopLevelRoute";

import BrandHubContainer from "../Brand/async";
import BrandViewContainer from "./async";

import {
  VIEW_SPONSOR_HISTORY_PERMISSION,
  VIEW_PRO_PERMISSION,
} from "utils/entity/user";

function BrandRoutes({ isDesktop }) {
  return (
    <Switch>
      <TopLevelRoute
        path="/brands"
        exact
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <BrandHubContainer />
      </TopLevelRoute>

      <TopLevelRoute
        path="/brands/:id"
        
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
        routeComponent={RegisteredRoute}
        notRegisteredComponent={RegistrationRequired}
        notAuthorizedComponent={() => <Redirect to={"/"} />}
        requiredPermissions={[
          VIEW_SPONSOR_HISTORY_PERMISSION,
          VIEW_PRO_PERMISSION,
        ]}
      >
        <BrandViewContainer />
      </TopLevelRoute>
    </Switch>
  );
}

export default BrandRoutes;

import { commonModuleGroup, podcastModuleGroup } from "modules/groups";
import { Switch } from "react-router-dom";

import TopLevelRoute from "pages/Common/TopLevelRoute";
import Error404 from "pages/Error404";

import ChartsHubPage from "../../ChartsHub/async";
import Charts from "./async";
import ChartsWithCategory from "./ChartsWithCategoryAsync";

function ChartsRoutes() {
  return (
    <Switch>
      <TopLevelRoute
        path="/charts"
        exact
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <ChartsHubPage />
      </TopLevelRoute>
      <TopLevelRoute
        path="/charts/:platformId(apple|spotify)/:countryId?/:categoryId?"
        exact
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <ChartsWithCategory />
      </TopLevelRoute>
      <TopLevelRoute
        path="/charts/:platformId(apple|spotify)/:countryId?/:categoryId?/:subCategoryId?"
        exact
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <Charts />
      </TopLevelRoute>

      <TopLevelRoute path="*" >
        <Error404 />
      </TopLevelRoute>
    </Switch>
  );
}

export default ChartsRoutes;
